
import React from 'react';
import { Route, Switch } from "react-router";
import Loadable from 'react-loadable';
import { Spin } from 'antd';

function Loading({ error }) {
  if (error) {
    return  <div className="mdle mnheight">An Error occured!!</div>;
  } else {
    return <Spin className="mdle mnheight"/>;
  }
}

export const Home = Loadable({
  loader: () => import('./module/homePage'),
  loading: Loading
});
export const About = Loadable({
  loader: () => import('./module/about'),
  loading: Loading
});
export const Faqs = Loadable({
  loader: () => import('./module/faqs'),
  loading: Loading
});
export const Contact = Loadable({
  loader: () => import('./module/contact'),
  loading: Loading
});
export const Login = Loadable({
  loader: () => import('./module/authentication/login/Loginform'),
  loading: Loading
});
export const Register = Loadable({
  loader: () => import('./module/authentication/register/CreateAccount'),
  loading: Loading
});
export const Success = Loadable({
  loader: () => import('./module/authentication/register/Success'),
  loading: Loading
});
export const PasswordRequest = Loadable({
  loader: () => import('./module/authentication/recoverPassword/requestPasswordChange'),
  loading: Loading
});
export const Instruction = Loadable({
  loader: () => import('./module/authentication/recoverPassword/Instruction'),
  loading: Loading
});
export const OtpWrapper = Loadable({
  loader: () => import('./module/authentication/recoverPassword/otpWrapper'),
  loading: Loading
});
export const UpdatePassword = Loadable({
  loader: () => import('./module/authentication/recoverPassword/UpdatePassword'),
  loading: Loading
});
export const VerifyAccount = Loadable({
  loader: () => import('./module/authentication/register/VerifyAccount'),
  loading: Loading
});
export const TermsAndCondition = Loadable({
  loader: () => import('./module/termsAndCondition'),
  loading: Loading
});
export const ComingSoon = Loadable({
  loader: () => import('./module/comingSoon'),
  loading: Loading
});
export const PrivacyAndPolicy = Loadable({
  loader: () => import('./module/privacyAndPolicy'),
  loading: Loading
});

class Routes extends React.Component {
  render(){
    return (
      <Switch>
        {/* <Route exact path="/" component={ComingSoon} /> */}
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/contact" component={Contact} />
        <Route path="/login" component={Login} />
        {/* <Route path="/register" component={Register} /> */}
        <Route path="/register" render={() => window.location = "https://app.logistical.user.ng/register"} />
        <Route path="/success" component={Success} />
        <Route path="/forgot-password" component={PasswordRequest} />
        <Route path="/instruction" component={Instruction} />
        <Route path="/otp" component={OtpWrapper} />
        <Route path="/update-password" component={UpdatePassword} />
        <Route path="/verify-account" component={VerifyAccount} />
        <Route path="/terms-condition" component={TermsAndCondition} />
        <Route path="/privacy-policy" component={PrivacyAndPolicy} />
        
      </Switch>
    );
}
}

export default Routes;
